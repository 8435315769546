import { render, staticRenderFns } from "./BurgerDialog.vue?vue&type=template&id=63daf860"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/frontend/components/ui/Button.vue').default,Button: require('/vercel/path0/frontend/components/ui/Button.vue').default})
